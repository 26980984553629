<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="nacNombre"
                type="text"
                label="Nombre de nacionalidad"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nacNombre),
                    rules.maxLength(nacNombre, 20)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="sssCodigo"
                label="Código SSS"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                dense
                outlined
                :rules="
                  sssCodigo == '0'
                    ? [rules.maxLength(sssCodigo, 4)]
                    : rules.required.concat(rules.maxLength(sssCodigo, 4))
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @update:openConfirm="cancelar"
        @onConfirm="save(saveNacionalidad)"
      />
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
  name: "EditNacionalidad",
  directives: { mask },
  components: { ConfirmDialog },
  props: {
    nacionalidadObj: {
      type: Array,
      required: true
    },
    editNacionalidadItem: {
      type: Object
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_NACIONALIDAD,
    newTitle: enums.titles.NUEVA_NACIONALIDAD,
    rules: rules,
    nacNombre: "",
    sssCodigo: null,
    saveNacionalidad: null,
    openConfirmDialog: false,
    textConfirmDialog: ""
  }),
  created() {
    if (this.editNacionalidadItem) {
      this.setNacionalidad();
    } else {
      this.newNacionalidad();
    }
  },
  methods: {
    ...mapActions({
      saveNacionalidades: "configAfiliaciones/saveNacionalidades",
      setAlert: "user/setAlert"
    }),
    async setNacionalidad() {
      this.nacNombre = this.editNacionalidadItem.nacNombre;
      this.sssCodigo = this.editNacionalidadItem.sssCodigo != null ? this.editNacionalidadItem.sssCodigo : 0;
    },
    async newNacionalidad() {
      this.formEditTitle = this.newTitle;
    },
    async canSave() {
      if (!this.sssCodigo) this.sssCodigo = 0;
      this.saveNacionalidad = {
        nacNombre: this.nacNombre,
        sssCodigo: this.sssCodigo,
        nacId: this.editNacionalidadItem?.nacId
      };
      let sameCodeSss;
      if (this.sssCodigo != null) {
        sameCodeSss = this.nacionalidadObj.find(
          x =>
            x.sssCodigo == this.sssCodigo &&
            x.nacId != this.nacionalidadObj.nacId
        );
      }
      if (sameCodeSss) {
        this.textConfirmDialog = `El código SSS "${this.sssCodigo}" ya se encuentra en "${sameCodeSss.nacNombre}". `;
      }
      this.textConfirmDialog += "¿Desea confirmar los cambios?";
      if (this.textConfirmDialog === "¿Desea confirmar los cambios?") {
        this.save(this.saveNacionalidad);
      } else {
        this.openConfirmDialog = true;
      }
    },
    async save(data) {
      const res = await this.saveNacionalidades(data);
      this.openConfirmDialog = false;
      this.textConfirmDialog = "";
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
      }
      this.closeModal();
      this.saveNacionalidad = null;
    },
    cancelar() {
      (this.saveNacionalidad = null), (this.textConfirmDialog = "");
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
